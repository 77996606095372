import { useAuth0 } from '@auth0/auth0-react';

import SignInView from '@/components/SignIn/SignInView';

const defaultProps = {};

type SignInContainerProps = {} & typeof defaultProps;

// eslint-disable-next-line no-empty-pattern
export function SignInContainer({}: SignInContainerProps) {
  const { loginWithRedirect } = useAuth0();
  return <SignInView onSignInClick={loginWithRedirect} />;
}

SignInContainer.defaultProps = defaultProps;

export default SignInContainer;
