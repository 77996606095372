import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Logo from '@/components/Logo';

const defaultProps = {};

type SignInViewProps = {
  onSignInClick: () => void;
} & typeof defaultProps;

// eslint-disable-next-line no-empty-pattern
export function SignInView({ onSignInClick }: SignInViewProps) {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 15,
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
        }}
      >
        <Box mb={2}>
          <Logo />
        </Box>
        <Typography component="h1" variant="h3">
          {t(`sign-in.sign-in`)}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={onSignInClick}
          >
            {t(`sign-in.sign-in`)}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

SignInView.defaultProps = defaultProps;

export default SignInView;
