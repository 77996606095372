import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';

import SignIn from '@/components/SignIn';

export function SignInIndex() {
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/`);
    }
  }, [isAuthenticated]);

  return <SignIn />;
}

export default SignInIndex;
